import React from "react"

export default [
  {
    title: () => (
      <span>
        We met in <strong>New&nbsp;York&nbsp;City</strong>
      </span>
    ),
    emoji: "🏙",
    backgroundImage:
      "photo-1532960401447-7dd05bef20b0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9",
    posterData: {
      location: { lng: -73.9821414235908, lat: 40.737508274021394 },
      showBorder: true,
      showSecondaryRoads: false,
      zoom: 11.199639425278974,
      backgroundColor: "#13181F",
      foregroundColor: "#FFF2A7",
      icon: "",
      textLayout: "circle",
      textStyle: "script",
      showIntro: false,
      locationLabel: "New York, NY, USA",
      lng: -74.0059728,
      lat: 40.7127753,
      label: "",
      title: "",
      theme: ["#f1f1f1", "#104566"]
    }
  },
  {
    title: () => (
      <span>
        First kiss in <strong>Charleston</strong>
      </span>
    ),
    emoji: "😘",
    backgroundImage: "photo-1512187849-463fdb898f21?ixlib=rb-1.2.1",
    posterData: {
      lng: -79.93105120000001,
      lat: 32.7764749,
      locationLabel: "Charleston, SC, USA",
      label: "",
      title: "",
      textTop: "First Kiss",
      textBottom: "Charleston SC",
      showPin: false,
      zoom: 11.279185852801332,
      backgroundColor: "#8b0808",
      foregroundColor: "#fde4ab",
      gradient: null,
      iconURL: null,
      icon: "heart",
      textLayout: "block",
      textStyle: "script",
      showIntro: false,
      location: {
        lng: -79.88609829226095,
        lat: 32.764852092041124
      },
      bounds: {
        _sw: {
          lng: -82.41459313027158,
          lat: 34.834709829400566
        },
        _ne: {
          lng: -82.34901668475308,
          lat: 34.91840463184336
        }
      },
      showControls: false,
      showBuy: false,
      banana: false,
      theme: ["#f1f1f1", "#b61717"],
      addressLocation: {
        lat: 32.7764749,
        lng: -79.93105120000001
      },
      showBorder: true,
      showSecondaryRoads: true,
      accentColor: null
    }
  },
  {
    title: () => (
      <span>
        Vows on <strong>Long Island</strong>
      </span>
    ),
    emoji: "💍",
    backgroundImage:
      "photo-1519741497674-611481863552?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9",
    posterData: {
      location: {
        lng: -73.1653662489311,
        lat: 40.80567878503942
      },
      textTop: "I Do.",
      textBottom: "40.789°N / -73.135°W",
      showBorder: true,
      showSecondaryRoads: false,
      zoom: 10.508788407298269,
      backgroundColor: "#f1f1f1",
      foregroundColor: "#b61717",
      iconURL: null,
      icon: "heart",
      textLayout: "block",
      textStyle: "script",
      showIntro: false,
      locationLabel: "Long Island, New York, USA",
      lng: -74.0059728,
      lat: 40.7127753,
      label: "",
      title: "",
      showPin: false,
      gradient: null,
      bounds: {
        _sw: {
          lng: -122.51005430200807,
          lat: 37.5954927557796
        },
        _ne: {
          lng: -122.27106190666194,
          lat: 37.889505101764044
        }
      },
      showControls: false,
      showBuy: false,
      banana: false,
      theme: ["#f1f1f1", "#b61717"],
      accentColor: null,
      addressLocation: {
        lat: 40.789142,
        lng: -73.13496099999998
      }
    }
  },
  {
    title: () => (
      <span>
        Honeymoon in <strong>San Francisco</strong>
      </span>
    ),
    emoji: "🌝",
    backgroundImage:
      "photo-1449034446853-66c86144b0ad?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9",
    posterData: {
      location: { lng: -122.43802811196701, lat: 37.684223474468965 },
      textTop: "San Francisco",
      textBottom: "",
      showBorder: false,
      showSecondaryRoads: false,
      zoom: 11.35918513400263,
      backgroundColor: "#fff",
      foregroundColor: "#000",
      iconURL: null,
      icon: "heart",
      textLayout: "circle",
      textStyle: "sans",
      showIntro: false,
      locationLabel: "San Francisco, CA, United States",
      lng: -122.41941550000001,
      lat: 37.7749295,
      label: "",
      title: "",
      showPin: false,
      gradient: null,
      bounds: {
        _sw: { lng: -122.51005430200807, lat: 37.5954927557796 },
        _ne: { lng: -122.27106190666194, lat: 37.889505101764044 }
      },
      showControls: false,
      showBuy: false,
      banana: false,
      theme: ["#fff", "#000"]
    }
  }
]
